import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import Slider from 'react-slick'
import SEO from '../components/seo'

const Heading = styled.h1`
  margin-bottom: 0.25em;
`
const Subheading = styled.p`
  margin-bottom: 2em;
`
const SingleCityServices = styled.div`
  flex: 0 0 25%;
  @media (max-width: 992px) {
    flex: 0 0 50%;
  }
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
  margin-bottom: 2em;
  a {
    color: #666;
    font-size: 0.9em;
  }
  h3 {
    a {
      color: #333;
    }
    margin-bottom: 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0.9em;
    line-height: 1;
  }
`

const ServicesList = props => {
  let { slug, location, locationslug, serviceTitle } = props
  return (
    <li>
      <Link to={'/cities/' + locationslug + '/' + slug} title={serviceTitle}>
        {serviceTitle}
      </Link>
    </li>
  )
}

const CitiesServicesList = props => {
  let { location, slug, services } = props
  return (
    <SingleCityServices>
      <h3>
        <Link
          to={'/cities/' + slug + '/'}
          title={location + ' Home Remodeling Contractor'}
        >
          {location}
        </Link>
      </h3>
      <ul>
        {services.edges.map((item, i) => {
          let cleanReplaceServiceTitle = item.node.serviceTitle.replace(
            /{{city}}/g,
            location
          )
          return (
            <ServicesList
              key={item.node.id}
              locationslug={slug}
              location={location}
              serviceTitle={cleanReplaceServiceTitle}
              slug={item.node.slug}
            />
          )
        })}
      </ul>
    </SingleCityServices>
  )
}

const ServiceArea = props => (
  <StaticQuery
    query={graphql`
      query getCitiesList {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              location
              slug
            }
          }
        }
        allContentfulServiceAreaContent {
          edges {
            node {
              id
              slug
              serviceTitle
              content {
                id
                content
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout pageProps={props}>
          <SEO
            title={'Service Areas | Solid Construction & Design'}
            description={
              'This page contains a list of our service areas. If you do not see your city, please call us to confirm if you are in our service area.'
            }
          />
          <div className="container brand-padding-y">
            <Heading>Service Areas</Heading>
            <Subheading>
              Below you'll find a list of our service areas in Northern
              California. Don't see your location listed below? Just give us a
              call. We'd be happy to learn more about your project and see what
              we can do to assist you.
            </Subheading>
            <div className="d-flex flex-wrap">
              {data.allContentfulServiceArea.edges.map((item, i) => {
                return (
                  <CitiesServicesList
                    key={item.node.id}
                    location={item.node.location}
                    slug={item.node.slug}
                    services={data.allContentfulServiceAreaContent}
                  />
                )
              })}
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default ServiceArea
